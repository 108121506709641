export default {
    accountMenu: {
        sessions: 'Kommende & letzte Sitzungen',
        notifications: 'Benachrichtigung',
        support: 'Hilfe & Support',
        information: 'Rechtliche Informationen',
        settings: 'Kontoeinstellungen',
        messages: 'Nachrichten',
        'expert-profile': 'Expertenprofil'
    },
    menu: {
        'bb-client': 'Mit BB wachsen',
        'bb-expert': 'Werde BB-Experte',
        home: 'Startseite',
        blog: 'Blog&News'
    },
    registration: 'Registrieren',
    enter: 'Anmelden',
    enterAccount: 'Konto anmelden',
    account: 'Mein Konto',
    logout: 'Abmelden',
    decline: 'Ablehnen',
    send: 'Senden',
    deleteAcc: 'Konto löschen',
    footer: {
        faq: 'FAQ',
        policy: 'Datenschutzrichtlinie'
    },
    session: {
        upcoming: 'Kommende Sitzungen',
        requested: 'Angefragte Sitzungen',
        recent: 'Letzte Sitzungen',
        cancelReason: 'Gib einen Grund für die Absage der Sitzung ein',
        reasonPlaceholder: 'Beschreibe den Grund für die Ablehnung',
        decline: 'Sitzung ablehnen',
        confirm: 'Sitzung bestätigen',
        join: 'Sitzung beitreten',
        start: 'Sitzung starten',
        finish: 'Sitzung abschließen',
        comments: 'Kommentare',
        myComments: 'Meine Kommentare',
        addComment: 'Neuen Kommentar hinzufügen',
        commentPlaceholder: 'Ihr Kommentar',
        clientComments: 'Kundenkommentare',
        coachComments: 'Expertenkommentare'
    },
    room: {
        upcoming: 'Zukünftige Räume',
        requested: 'Angeforderte Räume',
        recent: 'Kürzliche Räume',
        newRoom: 'Neuer Raum'
    },
    agreementText: 'Folgendes habe ich gelesen und erkläre mich damit einverstanden: Benutzervereinbarung,',
    userAgreement: 'Benutzervereinbarung',
    privacyPolicy: 'Datenschutzrichtlinie',
    readMore: 'Mehr erfahren',
    photoDesc: 'Füge ein echtes Foto hinzu, mit Gesicht wirkt es immer glaubwürdiger.',
    dayStart: 'Tagesbeginn',
    topic: 'Thema',
    name: 'Name',
    surname: 'Nachname',
    password: 'Passwort',
    birthday: 'Geburtsdatum',
    oldPass: 'Altes Passwort',
    newPass: 'Neues Passwort',
    confirmPass: 'Passwort bestätigen',
    forgotPass: 'Passwort vergessen',
    resetPassText: 'Ein Link zum Zurücksetzen Ihres Passworts wurde an Ihre E-Mail gesendet',
    or: 'oder',
    facebook: 'Facebook-Konto',
    apple: 'Apple-Konto',
    google: 'Google-Konto',
    becomeExpert: '',
    insertInfo: 'Füge deine persönlichen Informationen ein, um deine Reise als BBuddy-Experte zu beginnen',
    changeUserData: 'Du kannst deine Angaben jederzeit ergänzen oder ändern\n',
    price: 'Preis',
    duration: 'Dauer',
    search: 'Suche',
    searchExpert: 'Nach einem Experten suchen',
    sort: 'Sortieren',
    sortPriceAsc: 'Nach Preis aufsteigend',
    sortPriceDesc: 'Nach Preis absteigend',
    details: 'Details',
    sessionLang: 'Sitzungssprache',
    direction: 'Wegbeschreibung',
    fromTo: 'von $ bis $',
    apply: 'Anwenden',
    save: 'Speichern',
    edit: 'Bearbeiten',
    changePass: 'Passwort ändern',
    resetPass: 'Passwort zurücksetzen',
    getStarted: 'Loslegen',
    delete: 'Löschen',
    today: 'Heute',
    back: 'Zurück',
    backToExperts: 'Zurück zur Expertenliste',
    courseInfo: 'Kursinfo',
    expertBackground: 'Expertenhintergrund',
    profCertification: 'Professionelle Zertifizierung',
    practiceHours: 'Praxisstunden',
    supervisionCount: 'Supervision pro Jahr',
    outOf: 'von',
    schedule: 'Zeitplan',
    successfulCase: 'Erfolgreiche Fälle aus der Praxis',
    signUp: 'Jetzt anmelden',
    noData: 'Keine Daten',
    notFound: 'Nicht gefunden',
    skillsInfo: 'Fähigkeiten-Infos',
    trainings: 'Trainings',
    seminars: 'Seminare',
    courses: 'Kurse',
    mba: 'MBA-Information',
    aboutCoach: 'Über den Experten',
    education: 'Bildung',
    coaching: 'Expertenprofil',
    experiences: 'Praktische Erfahrung',
    payInfo: 'Zahlungsdaten',
    sessionDuration: 'Sitzungsdauer',
    experienceHours: 'Gesamtstunden praktischer Erfahrung',
    topics: 'Themen',
    selectTopic: 'Thema auswählen',
    title: 'Titel',
    description: 'Beschreibung',
    sessionCost: 'Sitzungskosten in Euro',
    yourTimezone: 'Deine Zeitzone',
    workTime: 'Arbeitszeit',
    startAt: 'Beginn um',
    finishAt: 'Ende um',
    day: 'Tag',
    addWorkingHours: 'Arbeitszeiten hinzufügen',
    specialisation: 'Spezialisierung',
    selectSpecialisation: 'Wählen Sie Ihre Spezialisierung, um fortzufahren',
    fillWeeklySchedule: 'Trage Sachen in deinen Wochenplan ein',
    beneficiaryName: 'Name des Empfängers',
    bicOrSwift: 'BIC/Swift-Code',
    association: 'Verband',
    level: 'Stufe',
    addDiploma: 'Zertifikat hinzufügen',
    university: 'Institution',
    sunday: 'So',
    monday: 'Mo',
    tuesday: 'Di',
    wednesday: 'Mi',
    thursday: 'Do',
    friday: 'Fr',
    saturday: 'Sa',
    addNew: 'Neu hinzufügen',
    mExperiences: 'Führungserfahrung',
    pay: 'Zahlung',
    sessionWishes: 'Schreiben Sie Ihre Wünsche zur Sitzung',
    successPayment: 'Erfolgreiche Zahlung',
    errorPayment: 'Zahlungsfehler',
    errors: {
        invalidEmail: 'Die E-Mail-Adresse ist ungültig',
        emptyEmail: 'Bitte geben Sie Ihre E-Mail ein',
        emptyPass: 'Bitte geben Sie Ihr Passwort ein',
        confirmPass: 'Bitte bestätigen Sie Ihr Passwort',
        notMatchPass: 'Die neuen Passwörter stimmen nicht überein',
        emptyCancelReason: 'Bitte gib den Grund ein',
        approvingSession: 'Fehler beim Genehmigen der Sitzung',
        finishingSession: 'Fehler beim Beenden der Sitzung',
        emptyComment: 'Bitte geben Sie Ihren Kommentar ein',
    },
}
