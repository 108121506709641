export default {
    accountMenu: {
        sessions: 'Upcoming & Recent Sessions',
        notifications: 'Notification',
        support: 'Help & Support',
        information: 'Legal Information',
        settings: 'Account Settings',
        messages: 'Messages',
        'expert-profile': 'Expert profile'
    },
    menu: {
        'bb-client': 'Start grow with BB',
        'bb-expert': 'Become BB Expert',
        home: 'Home',
        blog: 'Blog&News'
    },
    registration: 'Registration',
    enter: 'Enter',
    enterAccount: 'Enter account',
    account: 'My Account',
    logout: 'Log out',
    decline: 'Decline',
    send: 'Send',
    deleteAcc: 'Delete account',
    footer: {
        faq: 'FAQ',
        policy: 'Privacy Policy'
    },
    session: {
        upcoming: 'Upcoming Sessions',
        requested: 'Sessions Requested',
        recent: 'Recent Sessions',
        cancelReason: 'Enter a reason for cancelling the session',
        reasonPlaceholder: 'Describe the reason for the rejection',
        decline: 'Decline session',
        confirm: 'Confirm session',
        join: 'Join session',
        start: 'Start session',
        finish: 'Finish session',
        comments: 'Comments',
        myComments: 'My comments',
        addComment: 'Add new',
        commentPlaceholder: 'Your comment',
        clientComments: 'Client Comments',
        coachComments: 'Expert Comments'
    },
    room: {
        upcoming: 'Upcoming Rooms',
        requested: 'Rooms Requested',
        recent: 'Recent Rooms',
        newRoom: 'New Room'
    },
    agreementText: 'I have read and agree with the terms of the User Agreement,',
    userAgreement: 'User Agreement',
    privacyPolicy: 'Privacy Policy',
    readMore: 'Read more',
    photoDesc: 'Add a real photo, as a person\'s face is always more credible.',
    dayStart: 'Day start',
    topic: 'Topic',
    name: 'Name',
    surname: 'Surname',
    password: 'Password',
    birthday: 'Date of Birth',
    oldPass: 'Old Password',
    newPass: 'New Password',
    confirmPass: 'Confirm Password',
    forgotPass: 'Forgot password',
    resetPassText: 'A link to reset your password has been sent to your email',
    or: 'or',
    facebook: 'Facebook account',
    apple: 'Apple account',
    google: 'Google account',
    becomeExpert: '',
    insertInfo: 'Insert your personal information to start your journey as a BBuddy Expert',
    changeUserData: 'Your info can either be added or amended at anytime',
    price: 'Price',
    duration: 'Duration',
    search: 'Search',
    searchExpert: 'Search for an Expert',
    sort: 'Sort',
    sortPriceAsc: 'By price ascending',
    sortPriceDesc: 'By price descending',
    details: 'Details',
    sessionLang: 'Session Language',
    direction: 'Direction',
    fromTo: 'from $ to $',
    apply: 'Apply',
    save: 'Save',
    edit: 'Edit',
    changePass: 'Change password',
    resetPass: 'Reset password',
    getStarted: 'Get started',
    delete: 'Delete',
    today: 'Today',
    back: 'Back',
    backToExperts: 'Back to experts list',
    courseInfo: 'Course Info',
    expertBackground: 'Expert Background',
    profCertification: 'Professional Certification',
    practiceHours: 'Practice hours',
    supervisionCount: 'Supervision per year',
    outOf: 'out of',
    schedule: 'Schedule',
    successfulCase: 'Successful Cases From Practice',
    signUp: 'Sign up now',
    noData: 'No data',
    notFound: 'Not found',
    trainings: 'Trainings',
    seminars: 'Seminars',
    courses: 'Courses',
    mba: 'MBA Information',
    aboutCoach: 'About Expert',
    skillsInfo: 'Skills Info',
    education: 'Education',
    coaching: 'Expert profile',
    experiences: 'Practical experience',
    payInfo: 'Payment Info',
    sessionDuration: 'Session duration',
    experienceHours: 'Total hours of practical experience',
    topics: 'Topics',
    selectTopic: 'Select Topic',
    title: 'Title',
    description: 'Description',
    sessionCost: 'Session cost in euro',
    yourTimezone: 'Your timezone',
    workTime: 'Work time',
    startAt: 'Start at',
    finishAt: 'Finish at',
    day: 'Day',
    addWorkingHours: 'Add working hours',
    specialisation: 'Specialisation',
    selectSpecialisation: 'Select your specialisation to proceed',
    fillWeeklySchedule: 'Fill up your weekly schedule',
    beneficiaryName: 'Beneficiary Name',
    bicOrSwift: 'BIC/Swift code',
    association: 'Association',
    level: 'Level',
    addDiploma: 'Add Diploma',
    university: 'Institution',
    sunday: 'Su',
    monday: 'Mo',
    tuesday: 'Tu',
    wednesday: 'We',
    thursday: 'Th',
    friday: 'Fr',
    saturday: 'Sa',
    addNew: 'Add New',
    mExperiences: 'Managerial Experience',
    pay: 'Pay',
    sessionWishes: 'Write your wishes about the session',
    successPayment: 'Successful Payment',
    errorPayment: 'Payment Error',
    errors: {
        invalidEmail: 'The email address is not valid',
        emptyEmail: 'Please enter your E-mail',
        emptyPass: 'Please enter your password',
        confirmPass: 'Please confirm your password',
        notMatchPass: 'The new passwords you entered do not match',
        emptyCancelReason: 'Please enter the reason',
        approvingSession: 'Error approving session',
        finishingSession: 'Error finishing session',
        emptyComment: 'Please enter your comment',
    },
}
